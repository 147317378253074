const yup = require("yup");
export const dataFormMap = [
    {
        id: 'infoAlimentari',
        name: "infoAlimentari",
        title: 'Indica se hai necessità alimentari',
        subtitle: "",
        fields: [
            {
                id: 'nessunaEsigenzaAlimentare',
                name: 'nessunaEsigenzaAlimentare',
                label: "Nessuna",
                type: 'checkbox',
                validations: [],
                required: false,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 4,
                }
            },
            {
                id: 'vegetariano',
                name: 'vegetariano',
                label: "Vegetariano/a",
                type: 'checkbox',
                validations: [],
                required: false,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 4,
                }
            },
            {
                id: 'vegano',
                name: 'vegano',
                label: "Vegano/a",
                type: 'checkbox',
                validations: [],
                required: false,
                precompiled: false,
                params: {
                    string: ""
                },
                cols: {
                    xs: 12,
                    sm: 4,
                }
            },
            {
                id: 'celiaco',
                name: 'celiaco',
                label: "Celiaco/a",
                type: 'checkbox',
                validations: [],
                required: false,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 4,
                }
            },
            {
                id: 'dietaAltro',
                name: 'dietaAltro',
                label: 'Altro',
                type: 'text',
                required: false,
                validations: ['string'],
                precompiled: false,
                params: {
                    string: ""
                }
            },
        ]
    },
    {
        id: 'trasporto',
        name: "trasporto",
        title: 'Informazioni sul trasporto',
        fields: [
            {
                id: 'mezzoTrasporto',
                name: 'mezzoTrasporto',
                label: 'Indica la tipologia di trasporto*',
                type: 'radiogroup',
                required: true,
                validations: ['string', 'required'],
                precompiled: false,
                values: [
                    {
                        value: "auto",
                        label: "Auto"
                    },
                    {
                        value: "treno",
                        label: "Treno"
                    },
                    {
                        value: "aereo",
                        label: "Aereo"
                    },
                    {
                        value: "autonomo",
                        label: "Mezzi propri (metro/autobus/taxi)"
                    }
                ],
                cols: {
                    xs: 12,
                    sm: 12
                },
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'stazioneArrivo',
                name: 'stazioneArrivo',
                label: 'Stazione di arrivo',
                row:false,
                type: 'select',
                required: true,
                validations: ['string', 'when'],
                precompiled: false,
                values: [
                    {
                        value: "termini",
                        label: "Termini"
                    }
                ],
                params: {
                    when: [
                        ['mezzoTrasporto'], {
                            is: (mezzoTrasporto) => mezzoTrasporto === "treno",
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: "mezzoTrasporto",
                            value: "treno"
                        }
                    ]
                }
            },
            {
                id: 'fasciaOrariaTreno',
                name: 'fasciaOrariaTreno',
                label: 'Fascia oraria',
                row:false,
                type: 'select',
                required: true,
                validations: ['string', 'when'],
                precompiled: false,
                values: [
                    {
                        value: "prima",
                        label: "Arrivo entro le 08.30"
                    },
                    {
                        value: "seconda",
                        label: 'Arrivo entro le 09.30'
                    }
                ],
                params: {
                    when: [
                        ['mezzoTrasporto'], {
                            is: (mezzoTrasporto) => mezzoTrasporto === "treno",
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: "mezzoTrasporto",
                            value: "treno"
                        }
                    ]
                }
            },
            {
                id: 'codiceTreno',
                name: 'codiceTreno',
                label: 'Codice treno',
                type: 'text',
                required: false,
                validations: [],
                precompiled: false,
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: "mezzoTrasporto",
                            value: "treno"
                        }
                    ]
                }
            },
            {
                id: 'aeroportoArrivo',
                name: 'aeroportoArrivo',
                label: 'Aeroporto di arrivo',
                row:false,
                type: 'select',
                required: true,
                validations: ['string', 'when'],
                precompiled: false,
                values: [
                    {
                        value: "fiumicino",
                        label: 'Fiumicino'
                    }
                ],
                params: {
                    when: [
                        ['mezzoTrasporto'], {
                            is: (mezzoTrasporto) => mezzoTrasporto === "aereo",
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: "mezzoTrasporto",
                            value: "aereo"
                        }
                    ]
                }
            },
            {
                id: 'fasciaOrariaAereo',
                name: 'fasciaOrariaAereo',
                label: 'Fascia oraria',
                row:false,
                type: 'select',
                required: true,
                validations: ['string', 'when'],
                precompiled: false,
                values: [
                    {
                        value: "prima",
                        label: "Arrivo entro le 08.30"
                    },
                    {
                        value: "seconda",
                        label: 'Arrivo entro le 09.30'
                    }
                ],
                params: {
                    when: [
                        ['mezzoTrasporto'], {
                            is: (mezzoTrasporto) => mezzoTrasporto === "aereo",
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: "mezzoTrasporto",
                            value: "aereo"
                        }
                    ]
                }
            },
            {
                id: 'numeroVolo',
                name: 'numeroVolo',
                label: 'Numero volo',
                type: 'text',
                required: false,
                validations: [],
                precompiled: false,
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: "mezzoTrasporto",
                            value: "aereo"
                        }
                    ]
                }
            },
        ]
    },
    {
        id: 'privacy',
        name: "privacy",
        title: "Privacy",
        fields: [
            {
                id: 'privacyFormAxa',
                name: 'privacyFormAxa',
                label: <span>Autorizzazione al <a href={'/privacy.pdf'} target={'_blank'}>trattamento dei dati personali AXA</a>*</span>,
                type: 'checkbox',
                validations: ['bool',"required", "oneOf"],
                required: true,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 12
                },
                params: {
                    oneOf: [
                        [true], "Questo campo è obbligatorio"
                    ]
                }
            }
        ]
    }
]
