import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD7q3ZORjiNqndeVp1iXnWcVK-Q2e181EE",
    authDomain: "axa-convention-2023.firebaseapp.com",
    projectId: "axa-convention-2023",
    storageBucket: "axa-convention-2023.appspot.com",
    messagingSenderId: "860109408830",
    appId: "1:860109408830:web:7d1b8d2f995f174e06df6f"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()

export {auth, firebase}
