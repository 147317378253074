import {createTheme, responsiveFontSizes} from "@mui/material";

export const axaTheme = responsiveFontSizes(createTheme(
    {
        typography: {
          fontFamily:['SourceSansPro', 'sans-serif'].join(',')
        },
        components:{
          MuiInputBase:{
            styleOverrides:{
                root:{
                    '& input.Mui-disabled':{
                        WebkitTextFillColor: '#313131',
                    }
                }
            }
          }
        },
        palette: {
            primary: {
                main: '#007bad'
            },
            secondary:{
                main: '#ec4d33',
            }
        }
    }
))
