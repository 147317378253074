import logo from './logo.svg';
import './App.css';
import {Route, Routes} from "react-router-dom";
import {Typography} from "@mui/material";
import {Login} from "./pages/Login";
import {Registration} from "./pages/Registration";
import ProtectedRoute from "./components/ProtectedRoute";
import {Error} from "./pages/Error";
import {Home} from "./pages/Home";
import {RecoverPassword} from "./pages/RecoverPassword";
import {StreamingBackup} from "./pages/StreamingBackup";

function App() {
    return (
        <div>
            <Routes>
                <Route path="/*" element={<Login/>}/>

            </Routes>
        </div>
    );
}

export default App;
