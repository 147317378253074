import React from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress, Collapse,
    Container,
    Slide,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {auth} from "../firebase/clientApp";
import {Link, useNavigate} from "react-router-dom";

export const Login = () => {
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const navigate = useNavigate()

    const doLogin = async (e) => {
        e.preventDefault()
        setError(false)
        setLoading(true)
        await auth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                navigate('/', {replace: true})
            })
            .catch((error) => {
                setError(true)
            });
        setLoading(false)
    }

    return (
        <Box className={'login'}>

        </Box>
    )
}
