const yup = require("yup");
export const registrationMap = [
    {
        id: 'registrazioneUtente',
        name: "registrazioneUtente",
        title: 'Completa le informazioni per registrarti',
        subtitle: "",
        fields: [
            {
                id: 'nomeAgenzia',
                name: 'nomeAgenzia',
                label: 'Agenzia',
                type: 'text',
                required: false,
                validations: [],
                precompiled: true,
            },
            {
                id: 'codiceAgenzia',
                name: 'codiceAgenzia',
                label: 'Codice agenzia',
                type: 'text',
                required: false,
                validations: [],
                precompiled: true,
            },
            {
                id: 'nome',
                name: 'nome',
                label: 'Nome',
                type: 'text',
                required: false,
                validations: [],
                precompiled: true,
            },
            {
                id: 'cognome',
                name: 'cognome',
                label: 'Cognome',
                type: 'text',
                required: false,
                validations: [],
                precompiled: true,
            },
            {
                id: 'email',
                name: 'email',
                label: 'Email',
                type: 'email',
                required: true,
                validations: ['string','required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'cellulare',
                name: 'cellulare',
                label: 'Cellulare',
                type: 'text',
                required: false,
                validations: [],
                precompiled: false,
            }
        ]
    },
    {
        id: 'sezionePassword',
        name: "registrazioneUtente",
        title: 'Imposta la tua password',
        fields: [
            {
                id: 'password',
                name: 'password',
                label: 'Password',
                type: 'password',
                required: true,
                validations: ['string','required', "when"],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio",
                    when: [
                        [], {
                            then: (schema) => schema
                                .min(8, 'La password deve essere di almeno 8 caratteri')
                                .matches(/[0-9]/, 'La password deve contenere almeno un numero')
                                .matches(/[a-z]/, 'La password deve contenere almeno una lettera minuscola')
                                .matches(/[A-Z]/, 'La password deve contenere almeno una lettera maiuscola')
                                .matches(/[^\w]/, 'La password deve contenere almeno un carattere speciale'),
                        }
                    ]
                }
            },
            {
                id: 'repeatPassword',
                name: 'repeatPassword',
                label: 'Ripeti la password',
                type: 'password',
                required: true,
                validations: ['string','required',"when"],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio",
                    when: [
                        [],{
                            then: (schema) => schema.oneOf([yup.ref('password'), null], 'Le password devono coincidere')
                        }
                    ]
                }
            },
        ]
    },
    {
        id: 'privacy',
        name: "privacy",
        title: 'Privacy',
        fields: [
            {
                id: 'privacyAxa',
                name: 'privacyAxa',
                label: <span>Autorizzazione al <a href={'/privacy.pdf'} target={'_blank'}>trattamento dei dati personali AXA</a></span>,
                type: 'checkbox',
                validations: ['bool',"required", "oneOf"],
                required: true,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 12
                },
                params: {
                    oneOf: [
                        [true], "Questo campo è obbligatorio"
                    ]
                }
            }
        ]
    }
]
